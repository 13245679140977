import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import { useSettingsContext } from '../../components/settings';
import Main from './Main';
import Header from './header';
import NavVertical from './nav/NavVertical';
import './index.css';

export default function DashboardLayout() {
  const { themeLayout } = useSettingsContext();

  const [open, setOpen] = useState(false);

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  if (isNavHorizontal) {
    return (
      <Main>
        <Outlet />
      </Main>
    );
  }

  if (isNavMini) {
    return (
      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <Main>
          <Outlet />
        </Main>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <Main>
        <Outlet />
      </Main>
    </Box>
  );
}
