const tr = {
  streamline_shipping: 'Kargo sürecinizi Streamline ile',
  simplify_shipping:
    'Uluslararası gönderi sürecinizi Pochtachi ile basitleştirin:Güvenilir, Otomatik ve Sorunsuz',
  services: 'Hizmetler',
  discover_convenience:
    "Pochtachi'deki hizmetlerimizin kolaylık ve verimliliğini keşfedin. Gönderi süreçlerinizi basitleştirelim ve genel deneyiminizi geliştirelim.",
  delivery_list: 'Teslimat Listesi Oluşturma',
  automated_invoicing: 'Otomatik Faturalama',
  goodbye_manual_invoicing:
    'Manuel fatura oluşturmaya veda edin. Sistemimiz otomatik olarak faturaları oluşturarak sizin zaman kazanmanızı ve hataları ortadan kaldırır.',
  sms_notifications: 'SMS Bildirimleri',
  keep_customers_informed:
    'SMS özelliğimiz ile müşterilerinizi gönderileri hakkında bilgilendirin. Teslimat durumu hakkında zamanında güncellemeler alacaklardır.',
  customs_documentation: 'Gümrük Belgelendirme',
  smooth_customs_clearance:
    'Sistemimiz sizin için tüm gerekli gümrük belgelerini hazırlar ve sorunsuz bir gümrük muayenesi süreci sağlar.',
  unique_parcel_code: 'Benzersiz Paket Kodu',
  simplified_distribution:
    'Benzersiz paket kodu sistemimiz dağıtımı basitleştirir ve gönderilerin kolay takip ve yönetimini sağlar.',
  customer_support: 'Müşteri Desteği',
  ensure_satisfaction:
    'Sorularınızda ve sorunlarınızda size yardımcı olacak özel müşteri desteği sunuyoruz. Ekibimiz memnuniyetinizi sağlamak için burada.',
  why_pochtachi: 'Neden Pochtachi?',
  choose_pochtachi: "Pochtachi'yi seçin ve lojistik operasyonlarınızdaki farkı yaşayın.",
  streamline_operations: 'Operasyonları Optimize Edin',
  item_subtitle: 'öğe alt başlığı',
  osiyoAuthor: 'Ilhom Khujamatov, Asya Express (Türkiye)',
  osiyoDescription:
    "Pochtachi'ye geçtiğimizden beri gönderim süreçlerimiz inanılmaz derecede verimli hale geldi. Otomatik sistem, bize önemli ölçüde zaman kazandırıyor ve gerçek zamanlı izleme özelliği, gönderilerimizi her adımda takip etmemizi sağlıyor. Müşterilerimiz zamanında bildirimler ve sorunsuz teslimat deneyimiyle memnun kaldı. Şiddetle tavsiye edilir!",
  simplify_automate_tasks:
    'Görevlerinizi basitleştirin ve etkin sistemimizle otomatize edin. Manuel süreçlere veda edin ve değerli zaman kazanın.',
  enhanced_efficiency: 'Artırılmış Verimlilik',
  humoTitle: 'Humo Export',
  humoDescription:
    "Pochtachi'ye geçtiğimizden beri gönderim süreçlerimiz inanılmaz derecede verimli hale geldi. Otomatik sistem, bize önemli ölçüde zaman kazandırıyor ve gerçek zamanlı izleme özelliği, gönderilerimizi her adımda takip etmemizi sağlıyor. Müşterilerimiz zamanında bildirimler ve sorunsuz teslimat deneyimiyle memnun kaldı. Şiddetle tavsiye edilir!",
  humoAuthor: 'Zukhriddin, Humo Export (Türkiye)',
  minimize_errors:
    'Otomatik süreçlerimiz doğruluğu sağlar ve hataları en aza indirir, böylece daha verimli ve etkili çalışabilirsiniz.',
  superior_customer_experience: 'Üstün Müşteri Deneyimi',
  enhance_satisfaction:
    'Müşterilerinize zamanında bildirimler, doğru faturalar ve sorunsuz teslimat süreci ile etkileyin. Müşteri memnuniyetini artırın ve kalıcı ilişkiler kurun.',
  customizable_solutions: 'Özelleştirilebilir Çözümler',
  tailor_pochtachi:
    "Pochtachi'yi benzersiz gereksinimlerinize göre uyarlayın. Ayarları, bildirimleri ve iş akışlarını iş ihtiyaçlarınıza uyacak şekilde özelleştirin.",
  real_time_tracking: 'Gerçek Zamanlı Takip',
  greater_visibility_control:
    'Gönderilerinizin durumu hakkında bilgi sahibi olun, gerçek zamanlı izleme özelliği sayesinde teslimat sürecinin her adımını izleyin ve kontrol edin.',
  dedicated_support: 'Adanmış Destek',
  smooth_experience:
    'Uzman ekibimiz her adımda size yardımcı olmak için burada. Uygulamadan sürekli desteğe kadar kişiselleştirilmiş yardım sunarak sorunsuz bir deneyim sağlarız.',
  customer_testimonials: 'Müşteri Görüşleri',
  pochtachi_numbers: 'Pochtachi sayılarla',
  shipments_sent_out: 'Gönderiler yola çıktı',
  total_shipped_weight: 'Toplam gönderilen ağırlık',
  total_parcels_shipped: 'Toplam gönderilen paketler',
  contact_us: 'İletişime Geçin',
  contact_information: 'İletişim Bilgileri',
  follow_us: 'Bizi Takip Edin',
  stay_connected:
    'Sosyal medyada bizimle bağlantıda kalın ve en son haberler, güncellemeler ve promosyonlar alın.',
  simplifies_shipping:
    "Pochtachi uluslararası gönderi sürecinizi güvenilir ve otomatik çözümleri ile basitleştirir. Sorunsuz gönderi deneyimi yaşayın ve Pochtachi'nin akıcı operasyonlarıyla zaman kazanın.",
  links: 'Bağlantılar',
  legal: 'Yasal',
  contact: 'İletişim',
  customers: 'Müşteriler',
  privacy_policy: 'Gizlilik Politikası',
  terms_and_condition: 'Şartlar ve Koşullar',
  address: 'Shohidontepa 8A,\nTaşkent, Özbekistan',
  copyright: '© 2023. Tüm hakları saklıdır',
};

export default tr;
