const uz = {
  demo: {
    title: 'Oʻzbek',
    introduction:
      "Lorem Ipsum - bu bosma ishlar va nashr sohasining oddiy matni. Lorem Ipsum 1500-larda namelum tipografiyachi tarafidan yaratilgan, bu soha uchun standart matn bo'lib qolgan. U besh asr davomida butunlay o'zgarmas holda qolgani kabi, elektron nashr tizimiga o'tishda ham yashagan. 1960-larda Lorem Ipsumga o'xshash bo'lgan Letraset varaqalaridan foydalangan va undan keyin kompyuter dasturxoni Pagemaker shu kabi nashr dasturlarida Lorem Ipsum versiyalaridan foydalangan.",
  },
  docs: {
    hi: 'Salom',
    description: 'Yordam kerakmi? \n Iltimos, dokumentatsiyamizni koʻring.',
    documentation: 'dokumentatsiya',
  },
  app: 'ilova',
  user: 'foydalanuvchi',
  list: 'roʻyxat',
  edit: 'tahrirlash',
  shop: 'doʻkon',
  blog: 'blog',
  post: 'post',
  mail: 'pochta',
  chat: 'suhbat',
  cards: 'kartalar',
  posts: 'postlar',
  create: 'yaratish',
  kanban: 'kanban',
  general: 'umumiy',
  banking: 'bank',
  booking: 'bron qilish',
  profile: 'profil',
  account: 'hisob',
  product: 'mahsulot',
  invoice: 'invoice',
  details: 'tafsilotlar',
  checkout: 'amalga oshirish',
  calendar: 'taqvim',
  analytics: 'tahliliy',
  ecommerce: 'e-savdo',
  management: 'boshqaruv',
  menu_level: 'menyu darajasi',
  menu_level_2a: 'menyu darajasi 2a',
  menu_level_2b: 'menyu darajasi 2b',
  menu_level_3a: 'menyu darajasi 3a',
  menu_level_3b: 'menyu darajasi 3b',
  menu_level_4a: 'menyu darajasi 4a',
  menu_level_4b: 'menyu darajasi 4b',
  item_disabled: 'element oʻchirilgan',
  item_label: 'element yorligʻi',
  item_caption: 'element sarlavha',
  item_external_link: 'element tashqi havola',
  description: 'tafsilotlar',
  other_cases: 'boshqa holda',
  item_by_roles: 'rol boʻyicha element',
  only_admin_can_see_this_item: 'Faqat administrator bu elementni koʻra oladi',
  streamline_shipping: "bilan xalqaro jo'natma jarayonlarini soddalashtiring",
  simplify_shipping:
    "Pochtachi bilan xalqaro jo'natma jarayonlarini soddalashtiring: ishonchli, avtomatlashtirilgan va muammosiz",
  services: 'Xizmatlarimiz',
  discover_convenience:
    "Pochtachida xizmatlarimizning qanchalik qulayligi va samaradorligini bilib oling. Jo'natma jarayonlaringizni soddalashtiramiz va umumiy tajribangizni oshiramiz.",
  delivery_list: 'Tarqatuv jadvali generatsiyasi',
  automated_invoicing: 'Avtomatlashtirilgan Invoice',
  goodbye_manual_invoicing:
    "Invoicelarni qo'lda yozish bilan xayrlashing. Tizimimiz avtomatik ravishda Invoicelarni yaratadi, vaqtingizni tejaydi va xatolarni bartaraf qiladi.",
  sms_notifications: 'SMS Xabarnoma',
  generate_delivery_list:
    'Bizning avtomatik tarqatuv jadvali generatsiyasi bilan siz endi tarqatuv jadvalini avtomatik ravishda generatsiya qilib beradi.',
  keep_customers_informed:
    'Bizning SMS xabarnoma servisimiz bilan mijozlaringizni posilkalar holati haqida xabardor qilib turing.',
  customs_documentation: 'Bojxona Hujjatlari',
  smooth_customs_clearance:
    "Tizimimiz siz uchun bojxona uchun kerakli bo'ladigan barcha hujjatlarini tayyorlaydi.",
  unique_parcel_code: 'Universal posilka kodi',
  simplified_distribution:
    "Bizning universal posilka kodlash tizimimiz tarqatish jarayonini soddalashtiradi va yuklarni oson kuzatish va boshqarishni ta'minlaydi.",
  customer_support: "Mijozlarni Qo'llab-Quvvatlash",
  ensure_satisfaction:
    "Sizni har qanday savol qiynayotgan bo'lsa ham yordam uchun tayyormiz. Jamoamiz sizni mamnun qilmoq uchun shu yerda.",
  why_pochtachi: 'Nima uchun Pochtachi?',
  choose_pochtachi: "Pochtachini tanlang va xalqaro jo'natma operatsiyalarda farqni his qiling.",
  streamline_operations: 'Jarayonlarni soddalashtiring',
  simplify_automate_tasks:
    "Tizimimiz yordamida kundalik vazifalaringizni soddalashtiring va avtomatlashtiring. Qo'l mexnati jarayonlari bilan xayrlashing va qimmatli vaqtni tejang.",
  enhanced_efficiency: 'Samaradorlikni oshiring',
  minimize_errors:
    "Bizning avtomatlashtirilgan jarayonlarimiz aniqlikni ta'minlaydi va xatolarni minimallashtiradi, bu sizga yanada samarali ishlashga imkon beradi.",
  superior_customer_experience: 'Yuqori darajadagi mijoz tajribasi',
  enhance_satisfaction:
    'Impress your customers with timely notifications, accurate invoices, and a seamless delivery process. Enhance customer satisfaction and build long-lasting relationships.',
  customizable_solutions: 'Moslashuvchan yechimlar',
  tailor_pochtachi:
    'Tailor Pochtachi to match your unique requirements. Customize settings, notifications, and workflows to fit your business needs.',
  real_time_tracking: 'Online kuzatuv',
  greater_visibility_control:
    'Stay informed about the status of your shipments with our real-time tracking feature. Monitor every step of the delivery process for greater visibility and control.',
  dedicated_support: "Yuqori darajadagi qo'llab quvvatlash",
  smooth_experience:
    "Bizning mutaxassislar jamoasi har qadamda sizga yordam berish uchun shu yerdalar. Amalga oshirishdan tortib to doimiy qo'llab-quvvatlashgacha mukammal tajriba uchun shaxsiy yordam ko'rsatamiz.",
  customer_testimonials: 'Mijozlar sharhlari',
  hear_client_review:
    "Faqatgina so'zimizga ishonib qolmang - Mijozlarimiz pochtachi bilan ishlash tajribasi haqida nimalar deyishganini tinglang",
  pochtachi_numbers: 'Pochtachi raqamlarda',
  numbers_speak: "Bizning o'rnimizga raqamlar bir oz gapirsin :)",
  shipments_sent_out: "Jo'natmalar yuborildi",
  total_shipped_weight: "Jami jo'natilgan vazn",
  total_parcels_shipped: "Jami jo'natilgan posilkalar",
  contact_us: "Biz Bilan Bog'lanish",
  contact_information: "Bog'lanish uchun ma'lumot",
  main: 'Asosiy',
  follow_us: 'Bizni kuzatib boring',
  stay_connected:
    "Eng so'nggi yangiliklar, aksiyalar uchun ijtimoiy tarmoqlarda bizni kuzatib boring.",
  simplifies_shipping:
    "Pochtachi avtomatlashtirilgan va sinalgan yechimlar bilan xalqaro jo'natma jarayonlarini soddalashtiradi.",
  links: 'Havolalar',
  legal: 'Huquqiy havolalar',
  contact: 'Aloqa',
  customers: 'Mijozlar sharhi',
  privacy_policy: 'Maxfiylik siyosati',
  terms_and_condition: 'Shartlar va shartlar',
  pochtachi: 'pochtachi',
  address: "Shohidontepa 8A,Toshkent, O'zbekiston",
  wait_your_feedback: 'Sizdan xabar kutib qolamiz!',
  copyright: '© 2023. Barcha huquqlar himoyalangan',
  osiyoTitle: 'Osiyo Express',
  osiyoDescription:
    "Pochtachiga o'tganimizdan beri xalqaro jo'natma jarayonlarimiz nihoyatda samarali bo'ldi. Avtomatlashtirilgan tizim bizga ancha vaqtni tejadi, online kuzatuv tizimi esa mijozlarimizga posilkalarini har qadamda kuzatib borish imkonini berdi. Mijozlarimiz ushbu xizmatdan mamnun. o'z vaqtida bildirishnomalar va uzluksiz yetkazib berish tajribasi. Tavsiya qilaman!",
  osiyoAuthor: 'Ilhom Khujamatov, Osiyo Express (Turkiya)',
  humoTitle: 'Humo Export',
  humoDescription:
    "Pochtachiga o'tganimizdan beri xalqaro jo'natma jarayonlarimiz nihoyatda samarali bo'ldi. Avtomatlashtirilgan tizim bizga ancha vaqtni tejadi, online kuzatuv tizimi esa mijozlarimizga posilkalarini har qadamda kuzatib borish imkonini berdi. Mijozlarimiz ushbu xizmatdan mamnun. o'z vaqtida bildirishnomalar va uzluksiz yetkazib berish tajribasi. Tavsiya qilaman!",
  humoAuthor: 'Zuxriddin, Humo Export (Turkiya)',
  shipmentSent: "Jo'natma yuborildi",
  totalShippedWeight: "Jami jo'natilgan vazn",
  totalParcelsShipped: "Jami jo'natilgan posilkalar",
  look_forward: 'Sizdan xabar kutib qolamiz!',
};

export default uz;
