import { MAIN_PAGE_PATHS } from '../../../routes/paths';
import SvgColor from '../../../components/svg-color';

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  {
    subheader: 'general v4.1.0',
    items: [
      { title: 'main', path: `#${MAIN_PAGE_PATHS.MAIN}`, icon: ICONS.dashboard },
      { title: 'services', path: `#${MAIN_PAGE_PATHS.SERVICES}`, icon: ICONS.ecommerce },
      { title: 'why_pochtachi', path: `#${MAIN_PAGE_PATHS.WHY}`, icon: ICONS.analytics },
      { title: 'customers', path: `#${MAIN_PAGE_PATHS.TESTIMONIALS}`, icon: ICONS.analytics },
      { title: 'contact_us', path: `#${MAIN_PAGE_PATHS.CONTACTS}`, icon: ICONS.analytics },
    ],
  },
];

export default navConfig;
