// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ru = {
  pochtachi: 'pochtachi',
  streamline_shipping: 'Оптимизируйте процесс доставки с',
  simplify_shipping:
    'Упрощайте процесс международной доставки с Pochtachi:\nНадежно, Автоматизированно и Без лишних хлопот',
  services: 'Услуги',
  discover_convenience:
    'Откройте для себя удобство и эффективность наших услуг в Pochtachi. Доверьте нам упрощение процессов доставки и улучшите свой опыт.',
  delivery_list: 'Формирование Списка Доставки',
  automated_invoicing: 'Автоматическое Выставление Счетов',
  goodbye_manual_invoicing:
    'Прощайте, ручное создание счетов. Наша система автоматически генерирует счета, экономя ваше время и исключая ошибки.',
  sms_notifications: 'SMS Уведомления',
  keep_customers_informed:
    'Держите ваших клиентов в курсе о состоянии их посылок с помощью нашей функции SMS-уведомлений. Они будут получать своевременные обновления о статусе своих посылок.',
  customs_documentation: 'Документация для Таможни',
  smooth_customs_clearance:
    'Наша система подготавливает все необходимые документы для таможенного оформления, обеспечивая гладкий процесс таможенного клиринга.',
  unique_parcel_code: 'Уникальный Код Посылки',
  simplified_distribution:
    'Наша уникальная система кодирования посылок упрощает процесс дистрибуции и обеспечивает легкое отслеживание и управление грузами.',
  customer_support: 'Поддержка Клиентов',
  ensure_satisfaction:
    'Мы предоставляем высококлассную поддержку клиентов, чтобы помочь вам с любыми вопросами или проблемами. Наша команда всегда готова обеспечить ваше удовлетворение.',
  why_pochtachi: 'Почему Pochtachi?',
  choose_pochtachi: 'Выберите Pochtachi и почувствуйте разницу в ваших логистических операциях.',
  streamline_operations: 'Оптимизируйте операции',
  simplify_automate_tasks:
    'Упростите и автоматизируйте вашу повседневную работу с нашей эффективной системой. Прощайте, ручные процессы, и экономьте драгоценное время.',
  enhanced_efficiency: 'Повышенная эффективность',
  minimize_errors:
    'Наши автоматизированные процессы гарантируют точность и минимизируют ошибки, позволяя вам работать более эффективно и результативно.',
  superior_customer_experience: 'Превосходный опыт для клиентов',
  enhance_satisfaction:
    'Порадуйте своих клиентов своевременными уведомлениями, точными счетами и безупречным процессом доставки. Повышайте удовлетворенность клиентов и стройте долгосрочные отношения.',
  customizable_solutions: 'Персонализированные решения',
  tailor_pochtachi:
    'Настраивайте Pochtachi под свои уникальные требования. Персонализируйте настройки, уведомления и рабочие процессы, чтобы соответствовать потребностям вашего бизнеса.',
  real_time_tracking: 'Отслеживание посылок',
  greater_visibility_control:
    'Будьте в курсе статуса ваших отправлений с нашей функцией отслеживания в режиме реального времени. Следите за каждым шагом процесса доставки для большей прозрачности и контроля.',
  dedicated_support: 'Поддержка на высшем уровне',
  smooth_experience:
    'Наша команда экспертов готова помочь вам на каждом шаге. Мы предоставляем персонализированную поддержку, начиная с внедрения и продолжая на протяжении всего сотрудничества.',
  customer_testimonials: 'Отзывы клиентов',
  pochtachi_numbers: 'Pochtachi в цифрах',
  shipments_sent_out: 'Посылки отправлены',
  total_shipped_weight: 'Общий вес отправлений',
  total_parcels_shipped: 'Всего отправлено посылок',
  contact_us: 'Связаться с нами',
  contact_information: 'Контактная информация',
  follow_us: 'Следите за нами',
  stay_connected:
    'Оставайтесь на связи с нами в социальных сетях, чтобы получать самые свежие новости, обновления и акции.',
  simplifies_shipping:
    'Pochtachi упрощает ваш международный процесс доставки своими надежными и автоматизированными решениями. Испытайте беззаботную доставку и сэкономьте время с оптимизированными операциями Pochtachi.',
  links: 'Ссылки',
  legal: 'Правовая информация',
  contact: 'Связь',
  customers: 'Клиенты',
  privacy_policy: 'Политика конфиденциальности',
  terms_and_condition: 'Условия и положения',
  address: 'Shohidontepa 8A,Ташкент, Узбекистан',
  copyright: '© 2023. Все права защищены',
  osiyoTitle: 'Osiyo Express',
  osiyoDescription:
    'С переходом на «Почтачи» наши процессы доставки стали невероятно эффективными. Автоматизированная система экономит нам значительное количество времени, а функция отслеживания в реальном времени позволяет нам контролировать наши отправления на каждом этапе пути. Наши клиенты довольны своевременные уведомления и бесперебойная доставка. Настоятельно рекомендуется!',
  osiyoAuthor: 'Ильхом Худжаматов, Osiyo Express (Турция)',
  humoTitle: 'Humo Export',
  humoDescription:
    'С переходом на «Почтачи» наши процессы доставки стали невероятно эффективными. Автоматизированная система экономит нам значительное количество времени, а функция отслеживания в реальном времени позволяет нам контролировать наши отправления на каждом этапе пути. Наши клиенты довольны своевременные уведомления и бесперебойная доставка. Настоятельно рекомендуется!',
  humoAuthor: 'Зухриддин, Humo Export (Турция)',
  shippingSent: 'Посылка отправлена',
  shipmentSent: 'Посылка отправлена',
  totalShippedWeight: 'Общий вес отправления',
  totalParcelsShipped: 'Общее количество отправленных посылок',
  totalSippedWeight: 'Общий вес отправления',
  numbers_speak: 'Пусть цифры немного скажут за нас :)',
  hear_client_review:
    'Не верьте нам на слово – узнайте, что говорят наши клиенты о своем опыте работы с Почтачи.',
  look_forward: 'Мы с нетерпением ждем вашего ответа!',
  wait_your_feedback:
    'Мы были бы рады получить известия от вас! Не стесняйтесь обращаться к нам по любым вопросам, отзывам или возможностям партнерства.',
  main: 'Главная',
  generate_delivery_list:
    'Благодаря нашему автоматическому созданию списков доставки вы можете легко создавать полные списки для эффективного распределения.',
};

export default ru;
